<template>
  <div>
    <h4>Dashboard UI Only</h4>
  </div>
</template>
<script>
export default {
  name: "layout_only"
};
</script>
